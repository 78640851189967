a {
    text-decoration: none;
    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1,
h2,
h3 {
    letter-spacing: -0.5px;
}

h1,
.h1 {
    font-size: var(--fs-xxxxl);
}

h2,
.h2 {
    font-size: var(--fs-xxxl);
}

h3,
.h3 {
    font-size: var(--fs-xxl);
}

h4,
.h4 {
    font-size: var(--fs-xl);
}

h5,
.h5 {
    font-size: var(--fs-lg);
}

h6,
.h6 {
    font-size: var(--fs-base);
}

p {
    line-height: 160%;
}

.fs-base {
    font-size: var(--fs-base);
}

.fs-xs {
    font-size: var(--fs-xs);
}

.fs-sm {
    font-size: var(--fs-sm);
}

.fs-md {
    font-size: var(--fs-md);
}

.fs-lg {
    font-size: var(--fs-lg);
}

.fs-xl {
    font-size: var(--fs-xl);
}

.fs-xxl {
    font-size: var(--fs-xxl);
}

.fs-xxxl {
    font-size: var(--fs-xxxl);
}

.fs-xxxxl {
    font-size: var(--fs-xxxxl);
}

.fs-hero {
    font-size: var(--fs-hero);
}

.fs-hero-lg {
    font-size: var(--fs-hero-lg);
}

.fs-hero-xl {
    font-size: var(--fs-hero-xl);
}

.fs-hero-xxl {
    font-size: var(--fs-hero-xxl);
}

.fs-hero-xxxl {
    font-size: var(--fs-hero-xxxl);
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}


.icontext {
    display: flex;
    align-items: center;

    .icon {
        display: inline-block;
        height: 34px;
        width: 34px;
        background-size: cover;
        border-radius: 50%;
        margin-right: 5px;
    }

    .text {
        color: var(--text-secondary);
    }

    &.icontext-sm {
        .icon {
            width: 30px;
            height: 30px;
        }

        .text {
            font-size: var(--fs-xs);
        }
    }
}