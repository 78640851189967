.popsheet {
    position: fixed;
    max-width: 500px;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-secondary);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding: 30px;
    z-index: 998;
    margin-top: 80px;

    &::before {
        content: '';
        position: absolute;
        top: 10px;
        height: 4px;
        left: 0;
        right: 0;
        width: 40px;
        margin: auto;
        background-color: var(--white-40);
        border-radius: 25px;
    }

    .popsheet__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .popsheet__body {
        max-height: 70vh;
        overflow: scroll;
    }

    .popsheet__footer {
        padding: 20px;
    }

    .popsheet__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--black-80);
}

.drawer {}