.theme__light {
    --bg-primary: var(--white);
    --bg-secondary: #f8f8f8;
    --text-primary: var(--black);
    --text-secondary: var(--black-50);
    --input-bg: #f5f8fa;
    --input-text: var(--black);
    --border-primary: var(--black-08);

    input[type=checkbox] {
        border-color: var(--black-50);
    }

    input[type=checkbox]:checked {
        background-color: var(--black);
    }

    input[type=checkbox]:before {
        border-color: var(--white);
    }

    .select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z' fill='rgba(0,0,0,0.5)'%3E%3C/path%3E%3C/svg%3E");
    }

    .uploader {
        .uploader__field {
            background-color: var(--black-03);
        }
    }

    .navbar {
        background-image: none;

        .navbar__add {
            border-color: var(--white);
            transition: all 0.3s;
            line-height: 55px !important;

            &.navbar__add-clicked {
                background-color: var(--black-80);
                transform: rotate(45deg);
            }
        }
    }

    .loader {
        background-color: var(--white-80);
    }

    .menu {
        .menu__item {
            border-bottom-color: var(--black-10);
        }
    }

    .list {
        &.list-bg {
            .list__item {
                border-bottom-color: var(--black-10);
            }
        }
    }

    .css-t3ipsp-control,
    .css-13cymwt-control,
    .css-13cymwt-control:hover {
        background-color: var(--input-bg);
        border-color: var(--white-05);
        margin-bottom: 10px;
        height: 54px;
        border-radius: var(--border-radius);

        * {
            color: var(--black);
        }
    }

    .bg-red-dark {
        background-color: var(--red-light) !important;
    }

    .bg-yellow-dark {
        background-color: var(--yellow-light) !important;
    }

    .login {
        .login__inner {
            .popsheet {
                background-color: var(--black-secondary);
            }
        }
    }

    .btn {
        &.btn-line {
            border-color: var(--black-20);
            color: var(--text-secondary) !important;
        }
    }
}