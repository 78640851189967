@import '~react-datepicker/dist/react-datepicker.css';
@import 'constants';
@import 'fonts';
@import 'base';
@import 'display';
@import 'text';
@import 'nav';
@import 'popsheet';
@import 'form';
@import 'btn';
@import 'numpad';
@import 'alert';
@import 'list';
@import 'loader';
@import 'cards';
@import 'accordion';
@import 'stepper';
@import 'table';
@import 'colors';
@import 'timeline';
@import 'light';

.react-datepicker-wrapper {
    display: block;
}

.app {
    height: 100%;
}

.content {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 170px;
    overflow-y: scroll;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 30px;

    .header__subtitle {
        font-size: var(--fs-xl);
        color: var(--text-secondary);
    }

    .header__toolbar {
        display: flex;

        >* {
            margin-left: 20px;
        }
    }
}

.subheader {
    margin-bottom: 20px;
}

.icon {
    font-size: var(--fs-xxl);
}

.accounts {
    .accounts__item {
        display: block;
        margin: 20px;
        color: var(--text-secondary);
        border-radius: var(--border-radius);
        background-color: var(--white-10);
        padding: 20px;
        background-size: cover;

        &.pattern-1 {
            background-image: url('../../../public/assets/img/patterns/pattern-1.png');
        }

        &.pattern-2 {
            background-image: url('../../../public/assets/img/patterns/pattern-2.png');
        }

        &.pattern-3 {
            background-image: url('../../../public/assets/img/patterns/pattern-3.png');
        }

        &.pattern-4 {
            background-image: url('../../../public/assets/img/patterns/pattern-4.png');
        }

        .accounts__item__balance {
            font-size: var(--fs-xxxl);
            color: var(--white);

            small {
                display: block;
                color: var(--text-secondary);
                font-size: var(--fs-sm);
            }
        }

        .accounts__item__details {
            display: flex;
            margin-top: 60px;
            justify-content: space-between;

            .accounts__item__details__number {
                font-size: var(--fs-xl);
                font-family: 'Kredit', sans-serif;
            }
        }
    }
}

.transactions-form {
    .transactions-form__action {
        position: fixed;
        bottom: 20px;
        left: 20px;
        right: 20px;

        .bank {
            margin-bottom: 10px;
        }

        .transactions-form__action__submit {
            display: flex;
            column-gap: 10px;

            .btn-line {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    // background-color: var(--black) !important;
    // color: var(--white) !important;

    // * {
    //     color: var(--white) !important;
    // }

    // .menu {
    //     background: var(--black-secondary);
    // }

    // .control {
    //     background-color: var(--black-secondary);
    // }

    .login__inner {
        .login__inner__logo {
            max-height: 40px;
            max-width: 200px;
        }

        .login__inner__support {
            margin-top: 5px;
            margin-bottom: 60px;
            color: var(--text-secondary);
        }
    }

    .login__reset {
        cursor: pointer;
        margin-top: 10px;
        text-align: center;
        color: var(--text-secondary);
    }
}

.init {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    .init__inner {
        .inner__inner__logo {
            max-height: 40px;
            max-width: 200px;
        }

        .init__inner__support {
            margin-top: 5px;
            margin-bottom: 60px;
            color: var(--text-secondary);
        }
    }

    .init__reset {
        cursor: pointer;
        margin-top: 10px;
        text-align: center;
        color: var(--text-secondary);
    }
}

.app-header {
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    background-color: var(--accent);
    z-index: 99;

    .app-header__icon {
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        font-size: var(--fs-lg);
        color: var(--white);
    }

    .app-header__logo {
        max-height: 40px;
        max-width: 150px;
    }
}

.home {
    .home__balance {
        font-size: var(--fs-hero);

        small {
            display: block;
            font-size: var(--fs-sm);
            color: var(--text-secondary);
            margin-bottom: 5px;
        }
    }

    .home__summary {
        margin-top: 50px;
    }

    .home__cards {
        margin-top: 40px;
    }
}

.apps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-top: 40px;
    padding: 10px;

    .apps__item {
        text-align: center;

        .apps__item__icon {
            width: 100px;
            height: 100px;
            border-radius: var(--border-radius);
        }

        .apps__item__name {
            margin-top: 5px;
            font-size: var(--fs-xs);
        }
    }
}

#radarChart {
    position: relative;
    padding-top: 100%;

    >div {
        position: absolute !important;
        top: 0;
    }
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .profile__initial {
        width: 80px;
        height: 80px;
        background-color: var(--pastel-7);
        border-radius: 50%;
        color: var(--black-80);
        line-height: 80px;
        text-align: center;
        font-size: var(--fs-xxxxl);
    }

    .profile__info {
        margin-top: 10px;
        text-align: center;

        .profile__info__id {
            color: var(--text-secondary);
        }
    }

    .profile__image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.message {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 40px;

    img {
        width: 70%;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 600px) {

    body,
    .navbar,
    .app-header {
        max-width: 500px;
        margin: auto;
    }
}

.breakup {
    .breakup__item {
        text-align: center;
        align-items: center;
        justify-content: center;

        .breakup__item__number {
            width: 100%;
            font-size: var(--fs-xxxl);
            display: flex;
            justify-content: center;
            align-items: start;
        }

        .breakup__item__number__currency {
            color: var(--white) !important;
            font-size: var(--fs-base);
            padding-top: 5px;
            margin-right: 2px;
        }

        .breakup__item__label {
            font-size: var(--fs-sm);
        }
    }
}

.progress {
    height: 13px;
    background-color: var(--grey);
    border-radius: var(--border-radius);
    position: relative;

    .progress__status {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: var(--border-radius);
        background-color: var(--green);
    }
}

.stats {
    margin-top: 15px;

    .stats__item {

        .stats__item__inner {
            padding: 15px;
            border-radius: var(--border-radius);

            .stats__item__inner__icon {
                width: 40px;
                height: 40px;
                background: var(--white);
                border-radius: 50%;
                text-align: center;

                .ri {
                    line-height: 40px;
                }
            }

            .stats__item__inner__number {
                font-size: var(--fs-xxl);
            }

            .stats__item__inner__label {
                margin-top: 30px;
                font-size: var(--fs-lg);
            }
        }
    }
}