#webpack-dev-server-client-overlay {
  display: none;
}

@font-face {
  font-family: 'LSSans';
  font-display: auto;
  src: url(../../../public/assets/fonts/ls-sans-regular.woff) format('woff');
}

@font-face {
  font-family: 'LSSans';
  font-display: auto;
  src: url(../../../public/assets/fonts/ls-sans-medium.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'LSSans';
  font-display: auto;
  src: url(../../../public/assets/fonts/ls-sans-bold.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'LSCurrency';
  font-display: auto;
  src: url(../../../public/assets/fonts/ls-curr.woff) format('woff');
}

@font-face {
  font-family: 'LSCurrency';
  font-display: auto;
  src: url(../../../public/assets/fonts/ls-curr-bold.woff) format('woff');
  font-weight: 700;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html,
body,
.App,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'LSSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  max-height: 100%;
}

span {
  display: inline-block;
}

a,
button,
.cursor-pointer {
  cursor: pointer;
}


[role="button"] {
  cursor: pointer;
}