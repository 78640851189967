.container {
    padding: 30px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.col {
    flex: 1;
    box-sizing: border-box;
}

.flex {
    display: flex;
    flex-direction: row;

    &.flex-column {
        flex-direction: column;
    }

    &.align-center {
        align-items: center;
    }

    &.align-between {
        align-items: space-between;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-between {
        justify-content: space-between;
    }
}

$margin-values: 0, 2, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 90, 100, 150, 200, 300, 500;
$opacity-values: 5, 10, 25, 50, 100;
$directions: b, t, l, r, x, y;
$property-map: (
    t: top,
    b: bottom,
    l: left,
    r: right,
    x: x,
    y: y,
);

@each $value in $margin-values {
    .m-#{$value} {
        margin: #{$value}px !important;
    }

    .p-#{$value} {
        padding: #{$value}px !important;
    }

    .w-#{$value} {
        width: #{$value}#{'%'} !important;
    }
}

@each $value in $opacity-values {
    .opacity-#{$value} {
        opacity: #{$value*0.01} !important;
    }
}

@mixin margin-helper($prefix, $value) {
    @if $prefix =='x' {
        $property: map-get($property-map, $prefix);

        .m#{$prefix}-#{$value} {
            margin-left: #{$value}px !important;
            margin-right: #{$value}px !important;
        }

        .p#{$prefix}-#{$value} {
            padding-left: #{$value}px !important;
            padding-right: #{$value}px !important;
        }
    }

    @else if $prefix =='y' {
        $property: map-get($property-map, $prefix);

        .m#{$prefix}-#{$value} {
            margin-top: #{$value}px !important;
            margin-bottom: #{$value}px !important;
        }

        .p#{$prefix}-#{$value} {
            padding-top: #{$value}px !important;
            padding-bottom: #{$value}px !important;
        }
    }

    @else {
        $property: map-get($property-map, $prefix);

        .m#{$prefix}-#{$value} {
            margin-#{$property}: #{$value}px !important;
        }

        .p#{$prefix}-#{$value} {
            padding-#{$property}: #{$value}px !important;
        }
    }
}

@each $direction in $directions {
    @each $value in $margin-values {
        @include margin-helper(#{$direction}, $value);
    }
}