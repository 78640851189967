// @font-face {
//     font-family: 'ELMN';
//     font-style: normal;
//     font-weight: 400;
//     src: local('ELMN Regular'), url('../fonts/ELMN-Regular.woff') format('woff');
// }


// @font-face {
//     font-family: 'ELMN';
//     font-style: italic;
//     font-weight: 400;
//     src: local('ELMN Italic'), url('../fonts/ELMN-Italic.woff') format('woff');
// }


// @font-face {
//     font-family: 'ELMN';
//     font-style: normal;
//     font-weight: 200;
//     src: local('ELMN Thin Regular'), url('../fonts/ELMN-Thin.woff') format('woff');
// }


// @font-face {
//     font-family: 'ELMN';
//     font-style: italic;
//     font-weight: 200;
//     src: local('ELMN Thin Italic'), url('../fonts/ELMN-ThinItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'ELMN';
//     font-style: normal;
//     font-weight: 300;
//     src: local('ELMN Light Regular'), url('../fonts/ELMN-Light.woff') format('woff');
// }

// @font-face {
//     font-family: 'ELMN';
//     font-style: italic;
//     font-weight: 300;
//     src: local('ELMN Light Italic'), url('../fonts/ELMN-LightItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'ELMN';
//     font-style: normal;
//     font-weight: 500;
//     src: local('ELMN Medium Regular'), url('../fonts/ELMN-Medium.woff') format('woff');
// }


// @font-face {
//     font-family: 'ELMN';
//     font-style: italic;
//     font-weight: 500;
//     src: local('ELMN Medium Italic'), url('../fonts/ELMN-MediumItalic.woff') format('woff');
// }


// @font-face {
//     font-family: 'ELMN';
//     font-style: normal;
//     font-weight: 700;
//     src: local('ELMN Bold'), url('../fonts/ELMN-Bold.woff') format('woff');
// }


// @font-face {
//     font-family: 'ELMN';
//     font-style: italic;
//     font-weight: 700;
//     src: local('ELMN Bold Italic'), url('../fonts/ELMN-BoldItalic.woff') format('woff');
// }


// @font-face {
//     font-family: 'ELMN';
//     font-style: normal;
//     font-weight: 900;
//     src: local('ELMN Black Regular'), url('../fonts/ELMN-Black.woff') format('woff');
// }

// @font-face {
//     font-family: 'ELMN';
//     font-style: italic;
//     font-weight: 900;
//     src: local('ELMN Black Italic'), url('../fonts/ELMN-BlackItalic.woff') format('woff');
// }

// @font-face {
//     font-family: "ELMNCurrency";
//     font-display: auto;
//     src: url(../fonts/elmn-curr.woff) format('woff');
// }

// @font-face {
//     font-family: "ELMNCurrency";
//     font-display: auto;
//     src: url(../fonts/elmn-curr-bold.woff) format('woff');
//     font-weight: 700;
// }

@font-face {
    font-family: "Kredit";
    font-display: auto;
    src: url(../fonts/kredit.woff2) format('woff2');
}

@font-face {
    font-family: "Kredit Shine";
    font-display: auto;
    src: url(../fonts/kredit-shine.woff2) format('woff2');
}