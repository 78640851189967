:root {
    --white: #fff;
    --black: #000;
    --black-secondary: #1f202c;
    --red: #f1416c;
    --green: #0db559;
    --yellow: #ffc801;
    --blue: #0047bb;

    --yellow-dark: #755D28;
    --yellow-light: #fff8dd;

    --grey: #ccc;

    --red-dark: #3A2434;
    --red-light: #fff5f8;

    --pastel-1: #e4c4ae;
    --pastel-2: #c6d5b5;
    --pastel-3: #cef1e4;
    --pastel-4: #d9a6e2;
    --pastel-5: #b9b4d8;
    --pastel-6: #c0ecf0;
    --pastel-7: #f9d7e8;
    --pastel-8: #bad5f0;
    --pastel-9: #d5eff6;
    --pastel-10: #c1d5a9;

    --dark-purple: #311c42;
    --dark-yellow: #392b1b;

    --black-90: rgba(0, 0, 0, 0.9);
    --black-80: rgba(0, 0, 0, 0.8);
    --black-70: rgba(0, 0, 0, 0.7);
    --black-60: rgba(0, 0, 0, 0.6);
    --black-50: rgba(0, 0, 0, 0.5);
    --black-40: rgba(0, 0, 0, 0.4);
    --black-30: rgba(0, 0, 0, 0.3);
    --black-20: rgba(0, 0, 0, 0.2);
    --black-10: rgba(0, 0, 0, 0.1);
    --black-08: rgba(0, 0, 0, 0.08);
    --black-05: rgba(0, 0, 0, 0.05);
    --black-03: rgba(0, 0, 0, 0.03);

    --white-90: rgba(255, 255, 255, 0.9);
    --white-80: rgba(255, 255, 255, 0.8);
    --white-70: rgba(255, 255, 255, 0.7);
    --white-60: rgba(255, 255, 255, 0.6);
    --white-50: rgba(255, 255, 255, 0.5);
    --white-40: rgba(255, 255, 255, 0.4);
    --white-30: rgba(255, 255, 255, 0.3);
    --white-20: rgba(255, 255, 255, 0.2);
    --white-10: rgba(255, 255, 255, 0.1);
    --white-05: rgba(255, 255, 255, 0.05);
    --white-03: rgba(255, 255, 255, 0.05);

    --border-radius: 1rem;
    --border-radius-sm: 0.375rem;
    --border-radius-xs: 0.250rem;
    --border-radius-lg: 1.2rem;
    --border-radius-round: 25px;

    --fs-base: 1rem;
    --fs-xs: 0.75rem;
    --fs-sm: 0.875rem;
    --fs-md: 1.1rem;
    --fs-lg: 1.125rem;
    --fs-xl: 1.25rem;
    --fs-xxl: 1.5rem;
    --fs-xxxl: 1.875rem;
    --fs-xxxxl: 2rem;
    --fs-hero: 2.3rem;
    --fs-hero-lg: 3.75rem;
    --fs-hero-xl: 5rem;
    --fs-hero-xxl: 6rem;
    --fs-hero-xxxl: 7rem;

    // --accent: #fff;
    // --accent-text: #000;

    --bg-primary: var(--black);
    --bg-secondary: #1c1c1c;
    --bg-tertiary: #2f2f2f;

    --text-primary: var(--white);
    --text-secondary: #a5a5a5;

    --input-bg: var(--black-secondary);
    --input-text: var(--white);
    --link: #009ef7;
}