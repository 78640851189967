.numpad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    .numpad__button {
        cursor: pointer;
        text-align: center;
        font-size: var(--fs-xxl);
        font-weight: 200;
        padding: 15px 0;
        &.backspace {
            color: var(--red);
        }
    }
}