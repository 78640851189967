.stepper {
    display: flex;
    align-items: flex-start;
    position: relative;

    .stepper__line {
        position: absolute;
        top: 17px;
        z-index: 0;
        height: 1px;
        width: 100%;
        content: '';
        background-color: var(--bg-tertiary);

        &::before,
        &::after {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            margin-top: -5px;
            left: 0;
            border-radius: 50%;
            background-color: var(--bg-tertiary);
        }

        &::after {
            left: auto;
            right: 0;
        }
    }

    .stepper__item {
        z-index: 1;
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;

        &.stepper__item-active {
            .stepper__item__icon {
                background-color: var(--accent);
            }
        }

        &.stepper__item-done {
            .stepper__item__icon {
                background-color: var(--green);
            }
        }

        .stepper__item__icon {
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            background-color: var(--input-bg);
            border-radius: 50%;
        }

        .stepper__item__name {
            margin-top: 5px;
            font-size: var(--fs-xs);
        }
    }
}