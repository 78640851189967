table {
    border-collapse: collapse;
}

th {
    background: #ccc;
}

th,
td {
    border: 1px solid var(--white-10);
    padding: 8px;
    text-align: left;
}

tr:nth-child(even) {
    background: var(--white-10);
}

tr:hover {
    background: var(--white-20);
}