.accordion {
    border-radius: var(--border-radius);

    .accordion__item {
        border-bottom: 1px solid var(--white-20);
        overflow: hidden;
        cursor: pointer;

        &:first-child {
            border-radius: var(--border-radius) var(--border-radius) 0 0;
        }

        &:last-child {
            border-radius: 0 0 var(--border-radius) var(--border-radius);
            border-bottom: none;
        }


        .accordion__item__header {
            padding: 0 15px;
            height: 50px;
            line-height: 50px;
            background-color: var(--white-10);
            position: relative;
            color: var(--text-secondary);

            &::after {
                font-family: remixicon !important;
                content: '\ea4e';
                position: absolute;
                right: 15px;
            }

            .accordion__item__header__index {
                display: inline-block;
                height: 35px;
                width: 35px;
                line-height: 35px;
                background-color: var(--accent);
                border-radius: 50%;
                text-align: center;
                color: var(--white);
                margin-right: 10px;
                margin-left: -5px;
            }
        }

        .accordion__item__content {
            padding: 15px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out;
        }

        &.active {
            background-color: var(--white-10);

            .accordion__item__content {
                max-height: 9999px;
            }
        }
    }

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}