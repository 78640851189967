input,
textarea,
select {
    font-family: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.form-sm {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
}

.support-text {
    display: inline-block;
    margin-bottom: 10px;
    color: var(--yellow);
}

.form__label {
    display: block;
    color: var(--text-secondary);
    margin-top: 10px;
    margin-bottom: 7px;
    font-size: var(--fs-sm);
}

.form__item {
    margin-bottom: 10px;
}

.label {
    display: block;
    color: var(--text-secondary);
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: var(--fs-sm);
}

.control {
    caret-color: var(--accent);
    background-color: var(--input-bg);
    padding: 0 15px;
    width: 100vw;
    max-width: 100%;
    outline: none;
    border: none;
    text-align: left !important;
    color: var(--input-text);
    font-size: var(--fs-md);
    border-radius: var(--border-radius);
    border: 1px solid var(--border-primary);

    &:disabled {
        opacity: 0.5;
    }

    &:not(textarea) {
        height: 54px;
    }

    &.control-hero {
        font-size: var(--fs-hero);
        background-color: transparent;
        text-align: center !important;
        border: none;

        &::placeholder {
            // color: var(--accent);
            font-weight: 100;
        }
    }
}

.control-radio {
    display: block;
    margin: 10px 0;
    position: relative;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    input[type='radio'] {
        display: none;
    }

    .control-radio__label {
        padding-left: 28px;
    }

    .control-radio__label::before,
    .control-radio__label::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.3em;
        height: 1.3em;
        text-align: center;
        color: var(--grey);
        font-family: Times;
        border-radius: 50%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .control-radio__label::before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        box-shadow:
            inset 0 0 0 0.2em var(--grey),
            inset 0 0 0 1em var(--grey);
    }

    .control-radio__label:hover::before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        box-shadow:
            inset 0 0 0 0.3em var(--grey),
            inset 0 0 0 1em #c6c6c6;
    }

    input[type='radio']:checked+.control-radio__label::before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        box-shadow:
            inset 0 0 0 0.2em white,
            inset 0 0 0 1em var(--accent);
    }
}

textarea {
    &.control {
        padding: 15px;
    }
}

.select {
    position: relative;
    background-color: var(--input-bg);
    padding: 0 15px;
    height: 54px;
    width: 100%;
    outline: none;
    border: none;
    color: var(--text-primary);
    font-size: var(--fs-lg);
    border-radius: var(--border-radius);
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z' fill='rgba(255,255,255,0.5)'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center right 15px;
    border: 1px solid var(--border-primary);

    &.select-raw {
        color: var(--text-secondary);
        font-size: var(--fs-sm);
        background-color: transparent;
        padding: 0;
        display: inline;
        width: auto;
        padding-right: 35px;
        border: none;
    }
}

.xselect {
    position: relative;
    background-color: var(--bg-secondary);
    border-radius: var(--border-radius);
    padding: 10px;
    display: flex;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z' fill='rgba(255,255,255,0.5)'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center right 20px;

    &.xselect-dark {
        color: var(--white);
    }

    .xselect__icon {
        display: inline-block;
        height: 46px;
        width: 46px;
        background-size: cover;
        border-radius: var(--border-radius);
        margin-right: 10px;

        &.xselect__icon-xs {
            height: 24px;
            width: 24px;
        }
    }

    .xselect__text {
        .xselect__text__placeholder {
            color: var(--text-secondary);
        }

        .xselect__text__selected {
            font-size: var(--fs-lg);
        }
    }

    .xselect__dropdown {
        position: absolute;
        top: 60px;
        background-color: var(--bg-secondary);
        display: block;
        left: 5px;
        right: 5px;
        border-radius: var(--border-radius-lg);
        list-style: none;
        padding: 0;
        max-height: 200px;
        overflow: scroll;

        li {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;
            padding: 10px 15px;
            border-bottom: 1px solid var(--border-primary);
            font-size: var(--fs-sm);
        }
    }

    &.top {
        .xselect__dropdown {
            bottom: 60px;
            top: auto;
        }
    }
}

.form__item-dropzone {
    background-color: var(--input-bg);
    border-radius: var(--border-radius);
    padding: 20px;
    border: 1px dashed var(--white-30);
    font-size: var(--fs-sm);
    text-align: center;

    .dropzone-file {
        margin: 20px;
    }
}


.css-b62m3t-container {
    .css-1u9des2-indicatorSeparator {
        background-color: var(--white-10);
    }

    .css-1nmdiq5-menu {
        background-color: var(--input-bg);
    }

    .css-d7l1ni-option {
        background-color: var(--white-10);
    }

    .css-t3ipsp-control,
    .css-13cymwt-control,
    .css-13cymwt-control:hover {
        background-color: var(--input-bg);
        border-color: var(--border-primary);
        margin-bottom: 10px;
        height: 54px;
        border-radius: var(--border-radius);

        * {
            color: var(--white);
        }
    }

    .css-1xc3v61-indicatorContainer {
        color: var(--text-secondary) !important;
    }

}

.uploader {
    .uploader__label {
        color: var(--text-secondary);
    }

    .uploader__field {
        display: flex;
        align-items: center;
        background-color: var(--input-bg);
        border-radius: var(--border-radius);
        height: 50px;
        line-height: 50px;
        position: relative;
        padding-left: 60px;
        margin: 10px 0;
        color: var(--text-secondary);
        justify-content: space-between;
        padding-right: 50px;

        .css-b62m3t-container {
            line-height: 40px;

            .css-1im77uy-control {
                background-color: transparent;
                color: var(--white);
                border-color: transparent;
            }
        }

        &.uploader__field-fluid {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-right: 15px;
            height: auto;
            line-height: 150%;
        }

        .uploader__field__type,
        .uploader__field__key {
            width: 150px;
            margin-right: 10px;
            font-size: var(--fs-sm);
        }

        .uploader__field__key {
            margin-left: 15px;
        }

        .uploader__field__file {
            display: none;
        }

        .uploader__field__remove {
            color: var(--red);
            font-size: var(--fs-xxxl);
            position: absolute;
            right: 5px;
            cursor: pointer;
        }

        .uploader__field__refresh {
            font-size: var(--fs-xl);
            position: absolute;
            right: 15px;
            top: 15px;
        }

        .uploader__field-uploaded::before {
            position: absolute;
            right: 10px;
            top: 10px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--green);
        }

        .uploader__field-uploaded::after {
            font-family: remixicon !important;
            content: '\eb7b';
            position: absolute;
            top: 0;
            right: 15px;
            font-size: var(--fs-xl);
            color: var(--white);
        }

        &.uploader__field-image {
            padding: 10px;
        }

        &:not(.uploader__field-image)::before {
            display: inline-block;
            content: '';
            position: absolute;
            top: 15px;
            left: 15px;
            width: 30px;
            height: 30px;
            background-image: url(../../../public/assets/img/icons/file.svg);
            background-position: left center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &.none {
            padding-left: 0;

            &::before {
                display: none;
            }
        }

        &.pan {
            &::before {
                background-image: url(../../../public/assets/img/icons/pan.png);
            }
        }

        &.aadhaar {
            &::before {
                background-image: url(../../../public/assets/img/icons/aadhaar.png);
            }
        }

        &.id {
            &::before {
                background-image: url(../../../public/assets/img/icons/id.svg);
            }
        }

        .uploader__image {
            border-radius: var(--border-radius-xs);
            margin-right: 10px;
        }
    }
}

.required {
    &:after {
        content: "*";
        position: relative;
        font-size: inherit;
        color: var(--red);
        padding-left: 0.25rem;
    }
}

input[type=checkbox] {
    position: relative;
    border: 2px solid var(--white-50);
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 .6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    opacity: .5;
}

input[type=checkbox]:hover {
    opacity: 1;
}

input[type=checkbox]:checked {
    background-color: var(--white);
    opacity: 1;
}

input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid var(--black);
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
}

.location {
    border-radius: var(--border-radius);
    text-align: center;
}