.card {
    background: var(--bg-secondary);
    border-radius: var(--border-radius);

    &.card-bordered {
        border: 1px dashed var(--white-30);
    }

    .card__header {
        font-weight: 500;
        color: var(--text-secondary);
        border-bottom: 1px solid var(--white-10);
        padding: 15px;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    .card__content {
        padding: 20px;
    }

    &.card-special {
        background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);

        * {
            color: var(--white);
        }
    }

}

.cards {
    swiper-slide {
        width: auto;
        margin-right: 10px;
    }

    .cards__item {
        display: inline-flex;
        flex-direction: column;
        min-width: 200px;
        background-color: var(--pastel-1);
        padding: 20px;
        border-radius: var(--border-radius);
        color: var(--black);

        .cards__item__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;

            .cards__item__header__icon {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: var(--black-10);
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    font-size: var(--fs-xl);
                    color: var(--black-80);
                }
            }
        }

        .cards__item__body {
            .cards__item__body__name {
                margin-bottom: 5px;
            }

            .cards__item__body__heading {
                font-size: var(--fs-xxxl);
                // font-weight: 200;
            }
        }
    }
}