.navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-image: url('../../../public/assets/img/gradient.png');
    background-size: 100%;
    background-position: center bottom;

    .navbar__add {
        position: absolute;
        right: 0;
        left: 0;
        top: -20px;
        border: 5px solid var(--black);
        transition: all 0.3s;
        line-height: 55px !important;

        &.navbar__add-clicked {
            background-color: var(--bg-secondary);
            transform: rotate(45deg);
        }
    }

    .navbar__flymenu {
        padding: 0;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 120px;
        list-style: none;

        &.navbar__flymenu-visible {
            display: flex;
        }

        .navbar__flymenu__item {
            margin-bottom: 10px;

            .navbar__flymenu__item__link {
                display: inline-block;
                font-size: var(--fs-sm);
                padding: 5px 10px;
                border-radius: var(--border-radius-round);
            }
        }
    }

    .navbar__menu {
        list-style: none;
        display: flex;
        background-color: var(--bg-secondary);
        z-index: 999;
        padding: 20px 0px;
        margin: 20px;
        border-radius: var(--border-radius-lg);

        .navbar__menu__tab {
            padding: 0;
            flex: 1;
            text-align: center;

            .navbar__menu__tab__link {
                color: var(--text-secondary);

                &.active {
                    color: var(--accent-secondary);
                }

                .navbar__menu__tab__link__icon {
                    padding: 0 20px;
                    display: block;
                }

                .navbar__menu__tab__link__title {
                    font-weight: 500;
                    font-size: var(--fs-xs);
                }
            }
        }
    }
}

.tabbed {
    background-color: var(--bg-secondary);
    list-style: none;
    display: flex;
    padding: 7px;
    border-radius: var(--border-radius-round);
    margin-left: 10px;
    margin-right: 10px;

    &.tabbed-line {
        padding: 0;

        .tabbed__item {
            border-bottom: 4px solid transparent;
            border-radius: 0;
            height: auto;

            &.active {
                background-color: transparent;
                border-bottom-color: var(--accent);
            }
        }
    }

    .tabbed__item {
        cursor: pointer;
        height: 35px;
        line-height: 35px;
        border-radius: 25px;
        flex: 1;
        text-align: center;
        font-size: var(--fs-sm);

        &.active {
            background-color: var(--accent);
            color: var(--accent-text);
        }
    }
}

.menu {
    background: var(--bg-secondary);
    border-radius: var(--border-radius);
    list-style: none;
    padding: 0;

    .menu__item {
        padding: 20px;
        border-bottom: 1px solid var(--white-10);

        &:last-child {
            border-bottom: none;
        }

        .menu__item__info {
            display: flex;
            align-items: center;
            flex: 1;

            img {
                height: 40px;
                margin-right: 10px;
            }
        }

        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .ri {
            color: var(--text-secondary);
        }
    }
}