.color-red {
  color: var(--red) !important;
}

.color-yellow {
  color: var(--yellow) !important;
}

.color-yellow-dark {
  color: var(--yellow-dark) !important;
}

.color-green {
  color: var(--green) !important;
}

.color-black {
  color: var(--black) !important;
}

.color-blue {
  color: var(--blue) !important;
}

.color-white {
  color: var(--white) !important;
}

.color-secondary {
  color: var(--text-secondary) !important;
}

.color-link {
  color: var(--link) !important;
}

.bg-red {
  background-color: var(--red) !important;
}

.bg-red-dark {
  background-color: var(--red-dark) !important;
}

.bg-yellow {
  background-color: var(--yellow) !important;
}

.bg-yellow-dark {
  background-color: var(--yellow-dark) !important;
}

.bg-green {
  background-color: var(--green) !important;
}

.bg-blue {
  background-color: var(--blue) !important;
}

.bg-black {
  background-color: var(--black) !important;
}

.bg-white {
  background-color: var(--white) !important;
}

@for $i from 1 through 10 {
  .pastel-#{$i} {
    background-color: var(--pastel-#{$i}) !important;
  }
}